import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

class EventsRollTemplate extends React.Component {
  render() {
    const { data, limit } = this.props
    const { edges: posts } = data.allMarkdownRemark

    // workaround for now until limit via graphql query
    const recordsLimit = limit || (posts||[]).length

    return (
      <div className="columns is-multiline">
        {posts &&
          posts.slice(0, recordsLimit).map(({ node: post }) => (
            <div className="is-parent column is-6" key={post.id}>
              <article
                className={`events-list-item tile is-child box notification ${
                  post.frontmatter.featuredpost ? 'is-featured' : ''
                }`}
              >
                <header>
                  {post.frontmatter.featuredimage ? (
                    <div className="featured-thumbnail">
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                          width:
                            post.frontmatter.featuredimage.childImageSharp
                              .gatsbyImageData.width,
                          height:
                            post.frontmatter.featuredimage.childImageSharp
                              .gatsbyImageData.height,
                        }}
                      />
                    </div>
                  ) : null}
                  <p className="post-meta">
                    <Link
                      className="title has-text-primary is-size-4"
                      to={post.fields.slug}
                    >
                      {post.frontmatter.title}
                    </Link>
                    {
                      post.frontmatter.startdatetime && post.frontmatter.startdatetime.length ? (
                        <>
                        <span> &bull; </span>
                        <span className="subtitle is-size-5 is-block">
                          <strong>Event Date:</strong> <em>{(new Date(post.frontmatter.startdatetime)).toString()}</em>
                        </span>
                        </>
                      ) : null
                    }
                  </p>
                </header>
                <p>
                  {post.frontmatter.description ? (
                    <>{post.frontmatter.description}<br /></>
                  ) : null}
                  {
                    post.frontmatter.location ? (
                      <><strong>Location:</strong><address>{post.frontmatter.location}</address></>
                    ) : null
                  }
                  {
                    post.frontmatter.onlineevent && post.frontmatter.onlineevent.medium ? (
                      <><strong>Online Event:</strong> via {post.frontmatter.onlineevent.medium}</>
                    ) : null
                  }
                  <br />
                  <Link className="button" to={post.fields.slug}>
                    More details →
                  </Link>
                </p>
              </article>
            </div>
          ))}
      </div>
    )
  }
}

EventsRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}


export default function EventsRoll({limit}) {
  // TODO: Improve for pagination 
  // $limit: Int! = 2 and then limit: $limit worked but not configurable per component
  // - https://www.gatsbyjs.com/docs/adding-pagination/
  // - https://github.com/v4iv/gatsby-starter-business
  // - https://stackoverflow.com/questions/61823176/pagination-in-gatsbyjs-blog
  return (
    <StaticQuery
      query={graphql`
        query EventsRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "events-post" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                  startdatetime
                  featuredpost
                  featuredimage {
                    childImageSharp {
                      gatsbyImageData(
                        width: 120
                        quality: 100
                        layout: CONSTRAINED
                      )
                    }
                  }
                  location
                  description
                  onlineevent {
                    medium
                  }
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <EventsRollTemplate data={data} count={count} limit={limit} />}
    />
  );
}
